import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";

// custom imports
import "../Home/home.scss";
import { url, headers } from "../../components/API";
import { PosterPotrait } from "../../components/Poster";
import { bannersSettings, listSettings } from "../Home";
import { LoadingStack } from "../../components/Loading";

const Music = () => {
  const [musicPromos, setMusicPromos] = useState([]);
  const [moviesPlayList, setMoviesPlayList] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const getMusicBanners = () => {
    let bannersInfo = [];
    axios
      .get(url + "/promos?filter=albums", headers)
      .then((res) => {
        const promoBanners = res.data;
        promoBanners.promos.map((itm) => {
          axios
            .get(url + "/albums/" + itm.album, headers)
            .then((tempRes) => {
              bannersInfo.push({
                bannerImage: itm.bannerImage,
                ...tempRes.data,
              });
              setLoading(false);
            })
            .catch((err) => {
              bannersInfo.push({
                bannerImage: itm.bannerImage,
                _id: itm.movie,
              });
              setLoading(false);
            });
        });

        console.log("Album Banners - ", bannersInfo);
        setTimeout(() => {
          setMusicPromos(bannersInfo);
        }, 500);
      })
      .catch((err) => {
        console.log("Album Banners err - ", err);
      });


  };

  const getPlayList = () => {
    axios
      .get(url + "/search?filter=albums", headers)
      .then((musicRes) => {
        console.log("movies Data res - ", musicRes.data);
        setMoviesPlayList(
          musicRes.data ? musicRes.data : null
        );
        /* setMoviesGenres(
          "genres" in musicRes.data ? musicRes.data.genres : null
        ); */
        setLoading(false);
      })
      .catch((err) => {
        console.log("Home Page Data err - ", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getMusicBanners();
    getPlayList();
  }, []);

  return (
    <div className="full-width">
    {loading ? <LoadingStack banner={true} /> :

      <div className="full-width movies-page">
        {/* Banners starts */}
        {musicPromos ? (
          <div className="full-width banners homeBanners">
            <Slider {...bannersSettings}>
              {musicPromos.map((banner, ind) => (
                <Link
                  className="banner-item"
                  to={"/music/" + banner._id}
                  key={ind + "musalbadn"}
                >
                  <div className="banner-image full-width">
                    <img src={banner.bannerImage} alt="" />
                  </div>
                  <div className="banner-content">
                    <div className="banner-title full-width">
                      {banner.title ? banner.title : null}
                    </div>
                    <div className="banner-meta-info full-width">
                      {banner.genre
                        ? banner.genre.map((genr, ind) => (
                            <span className="each-meta-info" key={ind + "bGn"}>
                              {genr}
                            </span>
                          ))
                        : null}
                      {banner.language ? (
                        <span className="each-meta-info">
                          {banner.language}
                        </span>
                      ) : null}

                      {banner.language ? (
                        <span className="each-meta-info">
                          {banner.language}
                        </span>
                      ) : null}
                    </div>

                    {banner.description ? (
                      <div className="banner-meta-desc full-width">
                        {banner.description}
                      </div>
                    ) : null}
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        ) : null}
        {/* Banners Ends */}

        {/* Playlist starts */}
        <div className="full-width videosList">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                      <div
                        className="full-width playlist-row mt-4"
                      >

                        <div className="playlist-video-list full-width">
                          {moviesPlayList.length > 0 ?
                            <Slider {...listSettings}>
                              {moviesPlayList.map((movie, index) => {
                                let metaData = [];
                                if (movie.year) {
                                  metaData.push(movie.year);
                                }
                                if (movie.maturity) {
                                  metaData.push(movie.maturity);
                                }
                                if (movie.language) {
                                  metaData.push(movie.language);
                                }
                                return (
                                  <div
                                    className="full-width"
                                    key={"musalbdet" + index}
                                  >
                                    <PosterPotrait
                                      id={movie._id}
                                      image={
                                        movie.cardImage ? movie.cardImage : null
                                      }
                                      title={movie.title ? movie.title : null}
                                      genre={movie.genre ? movie.genre : null}
                                      metaData={metaData}
                                      desc={
                                        movie.description
                                          ? movie.description
                                          : null
                                      }
                                      type="movie"
                                      onClick={() =>
                                        history.push("/music/" + movie._id)
                                      }
                                    />
                                  </div>
                                );
                              })}
                            </Slider>
                            : null}

                        </div>
                      </div>


              </div>
            </div>
          </div>
        </div>
        {/* Playlist ends */}
      </div>
    }

    </div>
  );
};

export default Music;
