import React, { useState, useEffect, useRef, useContext } from "react";
import { animate, motion } from "framer-motion";
import { ArrowBackOutline, MailUnreadOutline } from "react-ionicons";
import { Form, Field, Formik, ErrorMessage } from "formik";
import { useHistory } from "react-router-dom";

import * as Yup from "yup";
import {
  ChevronForward,
  LogoFacebook,
  LogoGoogle,
  CheckmarkCircle,
} from "react-ionicons";
import {
  firebase_app as firebase,
  recaptcha,
  googleProvider,
  facebookProvider,
  currentUser,
} from "../../firebase";

// Custom import
import "./login.scss";
import { Context } from "../../../Context";
import { LOGIN_SUCCESS } from "../../reducer/types";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z0-9])(?=.*\d)(?=.*[#$^+=!*()@%&]).{8,10}$/;

const loginValidation = Yup.object().shape({
  phone: Yup.string()
    .required("Enter your Mobile number")
    .min(10, "Must be exactly 10 characters")
    .max(10, "Must be exactly 10 characters")
    .matches(phoneRegExp, "Only numbers please"),
  country: Yup.string(),
});

const otpValidation = Yup.object().shape({
  otp: Yup.string()
    .required("Enter OTP number received on your phone")
    .min(6, "Must be exactly 6 characters")
    .max(6, "Must be exactly 6 characters")
    .matches(/^\d+$/, "Only numbers please"),
});

const loginValidations = Yup.object().shape({
  loginEmail: Yup.string()
    .email("Please enter valid Email id")
    .required("Please enter registered Email id"),
});

const Login = () => {
  const [phoneLogin, setPhoneLogin] = useState(true);
  const [otp, setOtp] = useState(false);
  const [loginEmail, setLoginEmail] = useState(false);
  const [loginPhoneNumber, setLoginPhoneNumber] = useState(null);
  const [loginPhoneError, setLoginPhoneError] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [emailSentSuccess, setEmailSentSuccess] = useState(false);
  const [otpWrong, setOtpWrong] = useState(null);

  const history = useHistory();

  const {  state, dispatch  } = useContext(Context);

  const recaptchaContainer = useRef();

  const googleLogin = () => {
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((googleRes) => {
        console.log("Google Res - ", googleRes);
        console.log("Google Name - ", googleRes.additionalUserInfo.profile.name);
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            name: googleRes.additionalUserInfo.user.displayName
              ? googleRes.additionalUserInfo.user.displayName
              : null,
            email: googleRes.additionalUserInfo.profile.email
              ? googleRes.additionalUserInfo.profile.email
              : null,
          },
        });
        googleRes.user.getIdToken().then((googleUser) => {
          alert("Successfully Login! Token Id is in console log");
          console.log("Google user - ", googleUser);
        });
      })
      .catch((err) => {
        console.log("Google login error - ", err.message);
      });
  };

  const facebookLogin = () => {
    firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((facebookRes) => {
        console.log("Facebook Res - ", facebookRes);
        facebookRes.user.getIdToken().then((facebookUser) => {
          alert("Successfully Login! Token Id is in console log");
          console.log("Facebook login - ", facebookUser);
        });
      })
      .catch((err) => {
        console.log("Facebook login error - ", err.message);
      });
  };

  const loginWithPhoneNumber = (values) => {
    console.log("Phone number login values - ", values);
    const phoneNumber = values.country + values.phone;

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, recaptcha(recaptchaContainer.current))
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("code sent confirmation message - ", confirmationResult);
        setLoginPhoneNumber(phoneNumber);
        setOtp(true);
        setPhoneLogin(false);
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
        console.log("code sent error - ", error);
        setLoginPhoneError("Something went wrong, please try again");
        recaptcha().clear();
      });
  };

  const verifyOTP = (otpValue) => {
    window.confirmationResult
      .confirm(otpValue)
      .then((otpVerifyRes) => {
        console.log("Veirfy Otp Res - ", otpVerifyRes);
        otpVerifyRes.user.getIdToken().then((tokenId) => {
          alert(
            "Successfully Verified! uId = " +
              otpVerifyRes.user.uid +
              ", & Token Id is in console log"
          );
          console.log("Token id - ", tokenId);
        });
      })
      .catch((err) => {
        console.log("OTP err - ", err);
        console.log(err.message);
      });
  };

  const loginWithEmail = (values) => {
    console.log("Got Login Details - ", values.loginEmail);

    const actionCodeSettings = {
      url: "https://taandava.com/auth/redirect",
      // url: "http://localhost:3008/auth/redirect",
      handleCodeInApp: true,
    };

    console.log(actionCodeSettings.url);

    firebase
      .auth()
      .sendSignInLinkToEmail(values.loginEmail, actionCodeSettings)
      .then((loginEmailRes) => {
        console.log("Login Email Res - ", loginEmailRes);
        localStorage.setItem("email", values.loginEmail);
        setPhoneLogin(false);
        setOtp(false);
        setLoginEmail(false);
        setEmailSentSuccess(true);

        setTimeout(() => {
          history.push("/");
        }, 5000);
      })
      .catch((loginErr) => {
        console.log("Email Link Err - ", loginErr);
        setLoginError(loginErr.message);
      });
  };

  const backToPhoneLogin = () => {
    setPhoneLogin(true);
  };

  const goToPhoneLogin = () => {
    setPhoneLogin(true);
    setOtp(false);
    setLoginEmail(false);
  };

  const goToSignup = () => {
    setPhoneLogin(false);
    setOtp(false);
    setLoginEmail(false);
  };

  const goToOTP = () => {
    setPhoneLogin(false);
    setOtp(true);
    setLoginEmail(false);
  };

  const goToEmailLogin = () => {
    setPhoneLogin(false);
    setOtp(false);
    setLoginEmail(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0, animate);

    console.log("Ref - ", recaptchaContainer.current);

    return () => {};
  }, []);

  return (
    <div className="full-width ">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
            <div
              id="recaptchaContainer"
              ref={(node) => (recaptchaContainer.current = node)}
            ></div>
            {!emailSentSuccess ? (
              <div className="full-width loginBg bg-secondary rounded px-2 pt-3 pb-5 mt-3">
                {/* Phone Number Form Starts */}
                {phoneLogin ? (
                  <div className="phone-number-login full-width">
                    <div className="full-width text-center loginHeading mb-2">
                      Login to Continue
                    </div>

                    <div className="full-width login-form-block d-flex flex-wrap justify-content-center">
                      {loginPhoneError ? (
                        <div className="alert alert-danger">
                          {loginPhoneError}
                        </div>
                      ) : null}
                      <Formik
                        enableReinitialize
                        initialValues={{ phone: "", country: "+91" }}
                        validationSchema={loginValidation}
                        onSubmit={(values) => {
                          console.log(values);
                          loginWithPhoneNumber(values);
                        }}
                      >
                        {(formik) => {
                          const {
                            isValid,
                            dirty,
                            isValidating,
                            isSubmitting,
                          } = formik;
                          return (
                            <Form className="login-form mt-4">
                              <ul className="form-list list-unstyled full-width mb-0">
                                <li>
                                  <div className="form-item full-width">
                                    <div className="phone-country-block full-width">
                                      <select
                                        name="country"
                                        id="country"
                                        className="phone-country-code"
                                        defaultValue="+91"
                                      >
                                        <option value="+91">+91</option>
                                      </select>
                                      <Field
                                        name="phone"
                                        id="phone"
                                        type="tel"
                                        className="form-control phone-input"
                                        placeholder="Enter your phone number"
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="phone"
                                      component="div"
                                      className="form-error"
                                    />
                                  </div>
                                </li>

                                <li>
                                  <div className="form-item full-width mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary submit-phone-btn full-width"
                                      id="loginSubmit"
                                      disabled={
                                        isSubmitting
                                          ? !loginPhoneError
                                            ? true
                                            : false
                                          : false
                                      }
                                    >
                                      {isSubmitting ? (
                                        !loginPhoneError ? (
                                          <span>Please wait...</span>
                                        ) : (
                                          <span>
                                            Continue{" "}
                                            <ChevronForward
                                              title={"Back"}
                                              height="14px"
                                              width="14px"
                                              color="white"
                                            />
                                          </span>
                                        )
                                      ) : (
                                        <span>
                                          Continue{" "}
                                          <ChevronForward
                                            title={"Back"}
                                            height="14px"
                                            width="14px"
                                            color="white"
                                          />
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>

                    <div className="or-block full-width text-center my-3 text-gray">
                      OR
                    </div>
                    <div className="login-other-methods-block full-width text-center">
                      <button
                        className="btn toggle-other-login-methods-btn btn-lg"
                        type="button"
                        onClick={() => goToEmailLogin()}
                      >
                        Login with Email or Google, Facebook
                      </button>
                    </div>
                  </div>
                ) : null}
                {/* Phone Number Form Ends */}
                {/* OTP Validation Starts */}
                {otp ? (
                  <div className="otp-validation-form full-width">
                    <div className="login-top-action-block text-left">
                      <button
                        className="btn btn-link login-back-btn"
                        type="button"
                        onClick={() => goToPhoneLogin()}
                      >
                        <ArrowBackOutline
                          color={"#2f89fc"}
                          title={"Back"}
                          height="18px"
                          width="18px"
                        />
                        &nbsp;&nbsp;Back
                      </button>
                    </div>

                    <div className="full-width login-form-block d-flex flex-wrap justify-content-center">
                      <Formik
                        enableReinitialize
                        initialValues={{ otp: "" }}
                        validationSchema={otpValidation}
                        onSubmit={(values) => {
                          console.log(values);
                          verifyOTP(values.otp);
                        }}
                      >
                        {(formik) => {
                          const { isValid, dirty, isSubmitting } = formik;
                          return (
                            <Form className="login-form mt-4">
                              <div className="full-width otp-title mb-2">
                                Enter the 4-digit code sent to
                                <br />
                                {loginPhoneNumber ? loginPhoneNumber : null}
                              </div>
                              <ul className="form-list list-unstyled full-width mb-0">
                                <li>
                                  <div className="form-item full-width d-flex flex-wrap">
                                    <div className="otp-block full-width">
                                      <Field
                                        name="otp"
                                        id="otp"
                                        type="tel"
                                        className="form-control login-input otp-input"
                                        placeholder="OTP..."
                                      />
                                    </div>
                                    <ErrorMessage
                                      name="otp"
                                      component="div"
                                      className="form-error full-width"
                                    />
                                  </div>
                                </li>
                                <li>
                                  <div className="full-width text-white pt-1">
                                    {/* Didn't received OTP?
                                  <br />{" "}
                                  <button
                                    type="button"
                                    className="resend-btn btn-blue btn"
                                    disabled
                                  >
                                    Resend in 20 sec
                                  </button> */}
                                  </div>
                                </li>
                                <li>
                                  <div className="form-item full-width mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary submit-phone-btn full-width"
                                      disabled={isSubmitting ? true : false}
                                    >
                                      {isSubmitting ? (
                                        <span>Please wait...</span>
                                      ) : (
                                        <span>
                                          Continue{" "}
                                          <ChevronForward
                                            title={"Back"}
                                            height="14px"
                                            width="14px"
                                            color="white"
                                          />
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                ) : null}
                {/* OTP Validation Ends */}
                {/* Login  Starts */}
                {loginEmail ? (
                  <div className="otp-validation-form full-width">
                    <div className="login-top-action-block text-left">
                      <button
                        className="btn btn-link login-back-btn"
                        type="button"
                        onClick={() => goToPhoneLogin()}
                      >
                        <ArrowBackOutline
                          color={"#2f89fc"}
                          title={"Back"}
                          height="18px"
                          width="18px"
                        />
                        &nbsp;&nbsp;Back
                      </button>
                    </div>

                    <div className="full-width login-form-block d-flex flex-wrap justify-content-center">
                      <Formik
                        enableReinitialize
                        initialValues={{ loginEmail: "" }}
                        validationSchema={loginValidations}
                        onSubmit={(values) => {
                          console.log(values);
                          loginWithEmail(values);
                        }}
                      >
                        {(formik) => {
                          const { isValid, dirty, isSubmitting } = formik;
                          return (
                            <Form className="login-form mt-4">
                              <div className="full-width text-center loginHeading mb-2">
                                Continue with Email!!!
                              </div>
                              <ul className="form-list list-unstyled full-width mb-0">
                                {loginError ? (
                                  <li>
                                    <div className="full-width alert alert-danger">
                                      {loginError}
                                    </div>
                                  </li>
                                ) : (
                                  ""
                                )}
                                <li>
                                  <div className="form-item mb-2 full-width d-flex flex-wrap">
                                    <div className="full-width border-bottom border-gray">
                                      <Field
                                        name="loginEmail"
                                        id="loginEmail"
                                        type="email"
                                        className="form-control login-input"
                                        placeholder="Enter Emaild id"
                                      />
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="form-item full-width mt-2">
                                    <button
                                      type="submit"
                                      className="btn btn-primary submit-phone-btn full-width"
                                      disabled={isSubmitting ? true : false}
                                    >
                                      {isSubmitting ? (
                                        <span>Please wait...</span>
                                      ) : (
                                        <span>
                                          Continue{" "}
                                          <ChevronForward
                                            title={"Back"}
                                            height="14px"
                                            width="14px"
                                            color="white"
                                          />
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                </li>
                              </ul>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                    <div className="or-block full-width text-center my-2 text-gray">
                      OR
                    </div>
                    <div className="social-login-block full-width text-center">
                      <ul className="social-logins-list full-width list-unstyled">
                        <li>
                          <button
                            className="btn social-login facebook"
                            type="button"
                            onClick={() => facebookLogin()}
                          >
                            <LogoFacebook
                              color={"#ffffff"}
                              title={"Login with Facebook"}
                              height="18px"
                              width="18px"
                              cssClasses="social-login-icon"
                            />
                            Login with Facebook
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn social-login google"
                            type="button"
                            onClick={() => googleLogin()}
                          >
                            <LogoGoogle
                              color={"#ffffff"}
                              title={"Login with Google"}
                              height="18px"
                              width="18px"
                              cssClasses="social-login-icon"
                            />
                            Login with Google
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
                {/* Login  Ends */}
              </div>
            ) : (
              <motion.div
                initial={{ x: "100px", y: "100px", opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                className="otp-validation-form mt-4 full-width thanks-message bg-success rounded shadow"
              >
                <div className="thanks-title w-100 d-flex align-items-center">
                  <MailUnreadOutline
                    title={"Success"}
                    height="32px"
                    width="32px"
                    color="white"
                    cssClasses="mr-1"
                  />
                  AutoLogin Link sent to your email.
                </div>
                <div className="thanks-info mt-1 w-100">
                  We have sent you and email with Link for AutoLogin. Kindly
                  check mail, and click on Login button or copy paste link in
                  your browser.
                </div>
                <div className="w-100 pt-1">
                  <small>
                    <em>You will be redirected to home page in 5 seconds</em>
                  </small>
                </div>
              </motion.div>
            )}

            {/* After Sent Login Email Starts */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
