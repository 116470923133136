import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import { Play } from "react-ionicons";
import { LockClosed } from "react-ionicons";

// custom imports
import { url } from "../../components/API";
import { PosterPotrait } from "../../components/Poster";
import { listSettings, potratPosterSlick } from "../Home";
import "./movieDetail.scss";
import NoData from "../../components/NoData";
import { LoadingStack } from "../../components/Loading";

const MovieDetail = () => {
  const [movDet, setMovDet] = useState(null);
  const [simlMov, setSimlMov] = useState([]);
  const [trailer, setTrailer] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const updateSchema = (mDet) => {
    /*window.wf.logEvent( mDet.title, {
      content_type: 'Movie',
      content_id: mDet._id,
      items: [{ name: mDet.title }]
    });*/
    return {
      "@context": "https://schema.org",
      "@type": "Movie",
      actor:
        "actors" in mDet
          ? mDet.actors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      director:
        "directors" in mDet
          ? mDet.directors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      producer:
        "producers" in mDet
          ? mDet.producers.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      genre:
        "genres" in mDet
          ? mDet.genres.map((obj, ind) => {
              return { "@type": "Text", name: obj };
            })
          : [],
      //"isFamilyFriendly" :true ,
      copyrightYear: mDet.year,
      isAccessibleForFree: mDet.model === "free" ? true : false,
      duration: mDet.duration + "secs",
      description: mDet.description,
      name: mDet.title,
      image: mDet.cardImage,
      thumbnailURL: mDet.detailImage,
      url: window.location.href,
      startDate: mDet.availability === "restricted" ? mDet.startDate : null,
      endDate: mDet.availability === "restricted" ? mDet.endDate : null,
    };
  };

  const getMovieDetail = (movId) => {
    axios
      .get(url + "/movies/" + movId)
      .then((res) => {
        // set movie details
        setMovDet(res.data);

        // update schema.org
        updateSchema(res.data);

        // set similar movies data
        res.data.similarMovies.length
          ? setSimlMov(res.data.similarMovies)
          : setSimlMov([]);

        const trailer = "trailer" in res.data;

        setTrailer(trailer);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const detailPage = (id) => {
    history.push("/movies/" + id);
    getMovieDetail(id);
  };

  const playTrailer = (id) => {
    history.push("/movies/" + id);
  };

  useEffect(() => {
    const locUrl = window.location.pathname.split("/")[2];
    getMovieDetail(locUrl);
  }, []);

  return (
    <div className="full-width movie-detail-page">
      {loading ? (
        <LoadingStack banner="true" wide={true} />
      ) : (
        <>
          {/* Movie Detail Starts */}
          {movDet ? (
            <div className="full-width movie-detail-info">
              <div className="movie-poster full-width">
                <img src={movDet.detailImage} alt={movDet.title} />
                <div className="movie-action-block">
                  <button
                    className="btn movie-play-btn watch-movie-btn"
                    type="button"
                  >
                    <Play
                      color={"#ffffff"}
                      title="Watch Movie"
                      height="24px"
                      width="24px"
                      className="movie-play-btn-icon"
                    />
                    Watch Movie
                  </button>
                </div>
              </div>

              <div className="movie-info-block full-width">
                {movDet.model === "subscription" ? (
                  <div className="movie-type full-width mb-1">
                    <span className="premium-block">
                      <LockClosed
                        color={"#010101"}
                        title="Premium"
                        height="12px"
                        width="12px"
                        className="premium-icon"
                      />{" "}
                      Premium
                    </span>
                  </div>
                ) : null}
                <div className="movie-title full-width">{movDet.title} </div>
                <div className="movie-meta-info meta-list full-width">
                  {movDet.genre
                    ? movDet.genre.map((gen, ind) => {
                        <span className="each-meta-info each-meta-item">
                          {gen}
                        </span>;
                      })
                    : null}
                  <span className="each-meta-info each-meta-item">
                    {movDet.language}
                  </span>
                  <span className="each-meta-info each-meta-item">
                    {movDet.maturity}
                  </span>
                </div>
                <div className="movie-desc full-width mt-1">
                  <p>{movDet.description}</p>
                </div>
              </div>
            </div>
          ) : (
            <NoData />
          )}
          {/* Movie Detail ENDS */}
          {/* Trailers & extras Starts */}
          {trailer ? (
            <div className="trailers-extra full-width">
              <div className="full-width playlist-row mt-2">
                <h2 className="playlist-heading full-width pb-2">
                  <span className="playlist-heading-title">
                    Trailers &amp; Extras
                  </span>
                </h2>
                <div className="playlist-video-list full-width">
                  <Slider {...potratPosterSlick}>
                    <div className="full-width" key={movDet._id + "TXta"}>
                      <PosterPotrait
                        id={movDet._id}
                        image={movDet.detailImage ? movDet.detailImage : null}
                        title={
                          movDet.title ? movDet.title + " - trailer" : null
                        }
                        genre={movDet.genre ? movDet.genre : null}
                        metaData=""
                        desc={movDet.description ? movDet.description : null}
                        type="movie"
                        wide={true}
                        onClick={() => playTrailer(movDet._id)}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          ) : null}
          {/* Trailers & extras Ends */}

          {/* Similar Movies Starts */}
          {simlMov.length ? (
            <div className="full-width mt-5">
              <div className="full-width playlist-row">
                <h2 className="playlist-heading full-width pb-2">
                  <span className="playlist-heading-title">Similar Movies</span>
                </h2>
                <div className="playlist-video-list full-width">
                  <Slider {...listSettings}>
                    {simlMov.map((movie, index) => {
                      let metaData = [];
                      if (movie.year) {
                        metaData.push(movie.year);
                      }
                      if (movie.maturity) {
                        metaData.push(movie.maturity);
                      }
                      if (movie.language) {
                        metaData.push(movie.language);
                      }
                      return (
                        <div className="full-width" key={movie._id + "MGItm"}>
                          <PosterPotrait
                            id={movie._id}
                            image={movie.cardImage ? movie.cardImage : null}
                            title={movie.title ? movie.title : null}
                            genre={movie.genre ? movie.genre : null}
                            metaData={metaData}
                            desc={movie.description ? movie.description : null}
                            type="movie"
                            onClick={() => detailPage(movie._id)}
                          />
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          ) : null}
          {/* Similar Movies Ends */}
        </>
      )}
    </div>
  );
};

export default MovieDetail;
