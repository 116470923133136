import React from "react";

// Custom import
import "./poster.scss";

export const PosterPotrait = (props) => {
  let videoLink = () => {
    switch (props.type) {
      case "movie":
        return "/movies/" + props.id;
      case "series":
        return "/series/" + props.id;
      case "album":
        return "/album/" + props.id;

      default:
        break;
    }
  };

  return (
    <div
      className={props.wide ? "posterPotrait posterWide" : "posterPotrait"}
    >
      <button
        type="button"
        className="posterThumbnail btn"
        onClick={props.onClick}
      >
        {props.image ? <img src={props.image} alt="" /> : null}
      </button>

      <div className="posterInfo">
        <button
          type="button"
          className="posterInfoContent btn"
          onClick={props.onClick}
        >
          <div className="poster-title full-width text-truncate">
            {props.title}
          </div>
          {props.metaData && props.metaData.length > 0 ? (
            <div className="meta-list full-width">
              {props.metaData.map((meta, index) => (
                <span className="each-meta-item" key={"metD" + index}>{meta}</span>
              ))}
            </div>
          ) : null}
          {props.desc ? (
            <div className="poster-desc full-width">{props.desc}</div>
          ) : null}
        </button>
        <button
          type="button"
          className="btn btn-dark addWishListBlock full-width"
          onClick={() => props.addWishList}
        >
          <span>+ Add to WishList</span>
        </button>
        {props.removeWishList ? (
          <button
            type="button"
            className="btn btn-dark addWishListBlock removeWishList full-width"
            onClick={() => props.removeWishList}
          >
            <span>- Remove WishList</span>
          </button>
        ) : null}
      </div>
    </div>
  );
};
