import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import { Play } from "react-ionicons";
import { LockClosed } from "react-ionicons";

// custom imports
import { url } from "../../components/API";
import { PosterPotrait } from "../../components/Poster";
import { listSettings, potratPosterSlick } from "../Home";
import "../MovieDetail/movieDetail.scss";
import NoData from "../../components/NoData";
import { LoadingStack } from "../../components/Loading";

const MusicDetail = () => {
  const [albumDet, setAlbumDet] = useState(null);
  const [episodes, setEpisodes] = useState([]);
  const [similar, setSimilar] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const updateSchema = (mDet) => {
    /*window.wf.logEvent( mDet.title, {
      content_type: 'Movie',
      content_id: mDet._id,
      items: [{ name: mDet.title }]
    });*/
    return {
      "@context": "https://schema.org",
      "@type": "Movie",
      actor:
        "actors" in mDet
          ? mDet.actors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      director:
        "directors" in mDet
          ? mDet.directors.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      producer:
        "producers" in mDet
          ? mDet.producers.map((obj, ind) => {
              return { "@type": "Person", name: obj };
            })
          : [],
      genre:
        "genres" in mDet
          ? mDet.genres.map((obj, ind) => {
              return { "@type": "Text", name: obj };
            })
          : [],
      //"isFamilyFriendly" :true ,
      copyrightYear: mDet.year,
      isAccessibleForFree: mDet.model === "free" ? true : false,
      duration: mDet.duration + "secs",
      description: mDet.description,
      name: mDet.title,
      image: mDet.cardImage,
      thumbnailURL: mDet.detailImage,
      url: window.location.href,
      startDate: mDet.availability === "restricted" ? mDet.startDate : null,
      endDate: mDet.availability === "restricted" ? mDet.endDate : null,
    };
  };

  const getSeriesDetail = (albumId) => {
    axios.get(url + "/albums/" + albumId).then((res) => {
      // set movie details
      setAlbumDet(res.data);

      // update schema.org
      updateSchema(res.data);

      // episodes data

      "songs" in res.data ? setEpisodes(res.data.songs) : setEpisodes([]);

      "similarSeries" in res.data
        ? setSimilar(res.data.similarSeries)
        : setSimilar([]);

      console.log("Episodes - ", episodes);
      setLoading(false);

    }).catch(err => {
      setLoading(false);
    });

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const detailPage = (id) => {
    history.push("/series/" + id);
    getSeriesDetail(id);
  };

  useEffect(() => {
    const locUrl = window.location.pathname.split("/")[2];
    getSeriesDetail(locUrl);
  }, []);

  return (
    <div className="full-width movie-detail-page">
      {loading ? (
        <LoadingStack banner={true} wide={true} />
      ) : (
          <>
              {/* Series Detail Starts */}
              {albumDet ? (
                <div className="full-width movie-detail-info">
                  <div className="movie-poster full-width">
                    <img src={albumDet.detailImage} alt={albumDet.title} />
                    <div className="movie-action-block">
                      <button
                        className="btn movie-play-btn watch-movie-btn"
                        type="button"
                      >
                        <Play
                          color={"#ffffff"}
                          title="Watch Movie"
                          height="24px"
                          width="24px"
                          className="movie-play-btn-icon"
                        />
                        Watch Series
                      </button>
                    </div>
                  </div>

                  <div className="movie-info-block full-width">
                    {albumDet.model === "subscription" ? (
                      <div className="movie-type full-width mb-1">
                        <span className="premium-block">
                          <LockClosed
                            color={"#010101"}
                            title="Premium"
                            height="12px"
                            width="12px"
                            className="premium-icon"
                          />{" "}
                          Premium
                        </span>
                      </div>
                    ) : null}
                    <div className="movie-title full-width">{albumDet.title} </div>
                    <div className="movie-meta-info meta-list full-width">
                      {albumDet.genre
                        ? albumDet.genre.map((gen, ind) => {
                            <span className="each-meta-info each-meta-item">
                              {gen}
                            </span>;
                          })
                        : null}
                      <span className="each-meta-info each-meta-item">
                        {albumDet.language}
                      </span>
                      <span className="each-meta-info each-meta-item">
                        {albumDet.maturity}
                      </span>
                    </div>
                    <div className="movie-desc full-width mt-1">
                      <p>{albumDet.description}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <NoData />
              )}
              {/* Series Detail ENDS */}

              {/* Episodes Starts */}
              {episodes.length > 0 ? (
                <div className="full-width mt-5">
                  <div className="full-width playlist-row">
                    <h2 className="playlist-heading full-width pb-2">
                      <span className="playlist-heading-title">Songs</span>
                    </h2>
                    <div className="playlist-video-list full-width">
                      <Slider {...potratPosterSlick}>
                        {episodes.map((movie, index) => {
                          let metaData = [];

                          if (movie.season) {
                            metaData.push("Season " + movie.season);
                          }

                          if (movie.number) {
                            metaData.push("Episode " + movie.number);
                          }

                          return (
                            <div className="full-width" key={movie._id + "EPI"}>
                              <PosterPotrait
                                image={
                                  movie.cardImage
                                    ? movie.cardImage
                                    : albumDet.detailImage
                                }
                                title={movie.name ? movie.name : null}
                                metaData={metaData}
                                type="series"
                                onClick=""
                                wide={true}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Episodes Ends */}

              {/* Similar Movies Starts */}
              {similar.length > 0 ? (
                <div className="full-width mt-5">
                  <div className="full-width playlist-row">
                    <h2 className="playlist-heading full-width pb-2">
                      <span className="playlist-heading-title">Similar Albums</span>
                    </h2>
                    <div className="playlist-video-list full-width">
                      <Slider {...listSettings}>
                        {similar.map((movie, index) => {
                          let metaData = [];
                          if (movie.year) {
                            metaData.push(movie.year);
                          }
                          if (movie.maturity) {
                            metaData.push(movie.maturity);
                          }
                          if (movie.language) {
                            metaData.push(movie.language);
                          }
                          return (
                            <div
                              className="full-width"
                              key={movie._id + "SEItm"}
                            >
                              <PosterPotrait
                                id={movie._id}
                                image={movie.cardImage ? movie.cardImage : null}
                                title={movie.title ? movie.title : null}
                                genre={movie.genre ? movie.genre : null}
                                metaData={metaData}
                                desc={
                                  movie.description ? movie.description : null
                                }
                                type="series"
                                onClick={() => detailPage(movie._id)}
                              />
                            </div>
                          );
                        })}
                      </Slider>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* Similar Movies Ends */}
          </>
           )}
    </div>
  );
};

export default MusicDetail;
