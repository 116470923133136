import React from "react";
import "./page404.scss";

const Page404 = () => {
  return (
    <div className="full-width">
      <div className="page-error text-white">
        Page 404!!! <br />
        Page Not found
      </div>
    </div>
  );
};

export default Page404;
