import firebase from "firebase";

export const FirebaseConfig = {
  apiKey: "AIzaSyCfMrytvHk3gBcKIxTiLTHYmm290bSdKQI",
  authDomain: "tandav-225e8.firebaseapp.com",
  projectId: "tandav-225e8",
  storageBucket: "tandav-225e8.appspot.com",
  messagingSenderId: "738271668832",
  appId: "1:738271668832:web:4d36986ebfbfc439a2f105",
  measurementId: "G-XCJE64H5M2",
};


export const firebase_app = firebase.initializeApp(FirebaseConfig);

export const recaptcha = (id) => {
  return new firebase.auth.RecaptchaVerifier(id, {
    size: "invisible",
  });
};

export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export const currentUser = firebase.auth().currentUser;

export const isSignEmailLink = (context) => {
  return firebase.auth().isSignInWithEmailLink(context);
};